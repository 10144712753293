import React from "react";
import PropTypes from "prop-types";

//style
import classes from "../CSS/right_side_drawer.module.css";

const RightSideDrawer = props => (
	<div
		data-testid="rightDrawer"
		onClick={() => props.onClick()}
		className={
			props.open
				? [classes.rightSideDrawer, classes.rightSideDrawerOpen].join(
						" "
				  )
				: [classes.rightSideDrawer, classes.rightSideDrawerClose].join(
						" "
				  )
		}
	>
		{props.children}
	</div>
);

RightSideDrawer.propTypes = {
	onClick: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired
};

export default RightSideDrawer;
