import React from "react";
import styles from "../CSS/services_nav.module.css";
import NavigationItem from "../../UI/JS/navigation_item";

export const HorizontalServicesNav = ({ onClick }) => (
	<nav className={styles.horiNav} onClick={() => onClick()}>
		<>
			<NavigationItem
				to="/services/#solar-pv-technology"
				itemName="Solar-Pv Technology"
			/>
		</>
		<>
			<NavigationItem
				to="/services/#agric-tech-products"
				itemName="Ag tech products"
			/>
		</>
		<>
			<NavigationItem
				to="/services/#cold-box-store"
				itemName="Coldbox store"
			/>
		</>
		<>
			<NavigationItem
				to="/services/#post-harvest-grid"
				itemName="Post harvest grid"
			/>
		</>
	</nav>
);

export const VerticalServiceNav = ({ onClick }) => (
	<aside className={styles.aside}>
		<nav className={styles.vertNav} onClick={() => onClick()}>
			{/* <NavigationItems horizontal={false}> */}
			<NavigationItem
				to="/services/#solar-pv-technology"
				itemName="Solar-Pv Technology"
			/>
			<NavigationItem
				to="/services/#agric-tech-products"
				itemName="Ag tech products"
			/>
			<NavigationItem
				to="/services/#cold-box-store"
				itemName="Coldbox store"
			/>
			<NavigationItem
				to="/services/#post-harvest-grid"
				itemName="Post harvest grid"
			/>
		</nav>
	</aside>
);
