import React from "react";

import Logo from "../../UI/JS/logo";
import NavigationItems from "../../UI/JS/navigation_items";
import NavigationItem from "../../UI/JS/navigation_item";
import { Hamburger } from "../../UI/JS/icons";

import styles from "../CSS/topNav.module.css";

const topNav = ({ openDrawer }) => (
	<header className={styles.header} data-testid="topNav">
		<Logo />
		<nav className={styles.nav}>
			<NavigationItems horizontal>
				<NavigationItem to="/" itemName="HOME" />
				<NavigationItem to="/About_Us" itemName="ABOUT US" />
				<NavigationItem to="/FAQ" itemName="FAQ" />
			</NavigationItems>
		</nav>
		<Hamburger
			HamStyle={styles.HamStyle}
			hamBarStyle={styles.hamBarStyle}
			onClick={openDrawer}
		/>
	</header>
);

export default topNav;
