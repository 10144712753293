import React, { Component } from "react";
import Layout from "../../Layout/JS/layout";
import QuestionsAndAnswers from "../../../components/FAQ/JS/q&a";
import Footer from "../../../components/Layout/JS/footer";

class FAQ extends Component {
	componentDidMount() {
		document.title = "Manamuz Electric | FAQ";
	}

	render() {
		return (
			<Layout>
				<QuestionsAndAnswers />
				<Footer />
			</Layout>
		);
	}
}

export default FAQ;
