import React from "react";
import styles from "../CSS/supported_by.module.css";
import usaidf from "../../../assets/usaidf.webp";
import allon from "../../../assets/all-onlogo.png";
import elsevier from "../../../assets/elsevier.png";
import ieee from "../../../assets/ieee logo.png";
import ises from "../../../assets/ises.png";
import microgrid from "../../../assets/microgridacademy.png";
import oxfam from "../../../assets/oxfamlogo.png";
import realrmi from "../../../assets/realrmi.png";
import seforall from "../../../assets/seforall.jpeg";

const contact = () => (
	<section className={styles.section} id="contact">
		<h2 className={styles.h2}>Supported By</h2>
		<div className={styles.banners}>
			<div className={styles.verticalCenter}>
				<img
					className={styles.img}
					style={{width: '7%' }}
					src={usaidf}
					alt="USAIDF"
				/>
				<img
					className={styles.img}
					style={{width: '7%' }}
					src={allon}
					alt="All On"
				/>
				<img
					className={styles.img}
					src={elsevier}
					alt="Elsevier"
				/>
				<img
					style={{width: '10%' }}
					className={styles.img}
					src={ieee}
					alt="IEEE"
				/>
				<img
					className={styles.img}
					src={ises}
					alt="ISES"
				/>
			</div>
		</div>
		<div className={styles.banners}>
			<div className={styles.verticalCenter}>
				<img
					className={styles.img}
					style={{width: '5%' }}
					src={microgrid}
					alt="Microgrid"
				/>
				<img
					className={styles.img}
					src={oxfam}
					alt="OxFam"
				/>
				<img
					className={styles.img}
					src={realrmi}
					alt="Real RMI"
				/>
				<img
					className={styles.img}
					src={seforall}
					alt="SE for All"
				/>
			</div>
		</div>
	</section>
);

export default contact;
