import React, {lazy, Suspense, useEffect} from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, compose } from "redux";
import rootReducer from "./Reducers/index";
import ReactGA from 'react-ga';
import {BrowserRouter, Switch, Route, useLocation} from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

//Components
import Homepage from "./containers/Homepage/JS/homepage";
import FAQ from "./containers/FAQ/JS/faq";
import AboutUs from "./containers/AboutUs/JS/about_us";
import Services from "./containers/Services/JS/services";
import SocialImpact from "./containers/SocialImpact/JS/social_impact";
const CompanyDashboard = lazy(() =>
	import("./containers/CompanyDashboard/JS/company_dashboard")
);
const AdminLogin = lazy(() => import("./containers/CompanyDashboard/JS/login"));

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = composeEnhancers()(createStore);

const TRACKING_ID = "G-X9Q5BZLLFC";



const useGoogleAnalytics = () => {
	const location = useLocation();

	useEffect(() => {
		ReactGA.initialize(TRACKING_ID);
	}, []);

	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search);
	}, [location]);
}

const Routes = () => {
	useGoogleAnalytics();
	return (
		<Switch>
			<Route path="/social_impact" component={SocialImpact}/>
			<Route path="/services" component={Services}/>
			<Route path="/FAQ" component={FAQ}/>
			<Route path="/About_Us" component={AboutUs}/>
			<Route exact path="/" component={Homepage}/>
			<Suspense fallback={<p>Loading</p>}>
				<Route path="/admin/dashboard" component={CompanyDashboard}/>
				<Route path="/admin/login" component={AdminLogin}/>
			</Suspense>
		</Switch>
	);
};

const App = () => {

	return (
	<BrowserRouter>
		<Routes/>
	</BrowserRouter>
)};

ReactDOM.render(
	<Provider store={store(rootReducer)}>
		<App />
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
