import React from "react";
import { Link } from "react-router-dom";
import solarEnergy from "../../../assets/solar-energy.png";
import harvest from "../../../assets/harvest.png";
import coldRoom from "../../../assets/cold-room.png";
import tractor from "../../../assets/tractor.png";

//style
import styles from "../CSS/services.module.css";

const services = () => (
	<section className={styles.section}>
		<h2 className={styles.h2}>We are thinking beyond the national grid.</h2>
		<p className={styles.main_paragraph}>
			There is a promise of a very different future that relies heavily on
			Renewable energy and it can happen here.
		</p>
		<div className={styles.services}>
			<div className={styles.card}>
				<img
					className={styles.img}
					src={solarEnergy}
					alt="solar PV Technology"
				/>
				<h4>Solar PV Technology</h4>
				<p>
					Solar PV solutions for agro processing plants, rural agro communities and other critical sectors of the agriculture value chain.
				</p>
				<Link
					className={styles.link}
					to="/services/#solar-pv-technology"
				>
					Learn more
				</Link>
			</div>
			<hr />
			<div className={styles.card}>
				<img
					className={styles.img}
					src={tractor}
					alt="Agtech"
				/>
				<h4>Agtech Products</h4>
				<p>
					Electrified and energy efficient Agricultural equipment for production, processing and supply chain operations.
				</p>
				<Link className={styles.link} to="/services/#agric-tech-products">
					Learn more
				</Link>
			</div>
			<hr />
			<div className={styles.card}>
				<img
					className={styles.img}
					src={coldRoom}
					alt="cold storage"
				/>
				<h4>Coldbox Store</h4>
				<p>
					Third party cold chain logistics for storage and transport of perishable Agricultural Products.
				</p>
				<Link className={styles.link} to="/services/#cold-box-store">
					Learn more
				</Link>
			</div>
			<hr />
			<div className={styles.card}>
				<img
					className={styles.img}
					src={harvest}
					alt="harvest"
				/>
				<h4>Post Harvest Grid</h4>
				<p>
					Agro produce management platform for  connecting smallholder farmers to wholesale and retail markets.
				</p>
				<Link
					className={styles.link}
					to="/services/#post-harvest-grid"
				>
					Learn more
				</Link>
			</div>
		</div>
	</section>
);

export default services;
