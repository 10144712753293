import React from "react";

import styles from "../CSS/icons.module.css";

export const Ellipses = ({ vertical, onClick }) => (
	<div
		className={
			vertical ? styles.ellipsesVertical : styles.ellipsesHorizontal
		}
		onClick={onClick}
	>
		<div className={styles.dots} />
		<div className={styles.dots} />
		<div className={styles.dots} />
	</div>
);

export const Hamburger = ({ HamStyle, onClick, hamBarStyle }) => (
	<div className={HamStyle} onClick={() => onClick()} data-testid="hamburger">
		<div data-testid="bar" className={hamBarStyle} />
		<div data-testid="bar" className={hamBarStyle} />
		<div data-testid="bar" className={hamBarStyle} />
	</div>
);
