import React, { Component } from "react";
import Layout from "../../Layout/JS/layout";
import {
	VerticalServiceNav,
	HorizontalServicesNav
} from "../../../components/Services/JS/services_nav";
import SolarPvTechnology from "../../../components/Services/JS/solar_pv_technology";
import AgricTechProducts from "../../../components/Services/JS/agric_tech_products";
import ColdboxStore from "../../../components/Services/JS/cold_box_store";
import PostHarvestGrid from "../../../components/Services/JS/post_harvest_grid";
import Footer from "../../../components/Layout/JS/footer";

import styles from "../CSS/services.module.css";

class Services extends Component {
	state = { activeService: window.location.hash.split("#")[1] };

	componentDidMount() {
		document.title = "Manamuz Electric | Services";
	}

	switchService = () => {
		const { activeService } = this.state;
		switch (activeService) {
			case "solar-pv-technology":
				return <SolarPvTechnology />;
			case "agric-tech-products":
				return <AgricTechProducts />;
			case "cold-box-store":
				return <ColdboxStore />;
			case "post-harvest-grid":
				return <PostHarvestGrid />;
			default:
				return;
		}
	};

	onNavClick = () => {
		const currentPage = window.location.hash.split("#")[1];
		this.setState({ activeService: currentPage });
	};

	render() {
		return (
			<Layout>
				<main className={styles.container}>
					<VerticalServiceNav onClick={this.onNavClick} />
					<HorizontalServicesNav onClick={this.onNavClick} />
					{this.switchService()}
				</main>
				<Footer />
			</Layout>
		);
	}
}

export default Services;
