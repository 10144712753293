import React, { Component } from "react";
import Layout from "../../Layout/JS/layout";
import SocialImpactDetails from "../../../components/SocialImpact/JS/social_impact";
import Footer from "../../../components/Layout/JS/footer";

class SocialImpact extends Component {
	componentDidMount() {
		document.title = "Manamuz Electric | Social Impact";
	}

	render() {
		return (
			<Layout>
				<SocialImpactDetails />
				<Footer />
			</Layout>
		);
	}
}

export default SocialImpact;
