import React from "react";
import { NavLink } from "react-router-dom";

import classes from "../CSS/navigation_item.module.css";

const navigationItem = ({ itemName, to }) => {
	return (
		<li className={classes.NavigationItem}>
			<NavLink
				exact
				to={to}
				activeClassName={classes.Active}
				data-testid="nav_link"
			>
				{itemName}
			</NavLink>
		</li>
	);
};

export default navigationItem;
