import React from "react";

import styles from "../CSS/footer.module.css";

const footer = () => (
	<footer className={styles.footer}>
		<div className={styles.social}>
			<a
				href="https://linkedin.com/company/manamuz-electric-ltd"
				target="_blank"
				rel="noopener noreferrer"
			>
				<i className="fab fa-linkedin-in" />
			</a>
			<a
				href="https://www.twitter.com/manamuzelectric"
				target="_blank"
				rel="noopener noreferrer"
			>
				<i className="fab fa-twitter" />
			</a>
			<a
				href="https://www.facebook.com/manamuzelectric"
				target="_blank"
				rel="noopener noreferrer"
			>
				<i className="fab fa-facebook" />
			</a>
			<a
				href="https://www.instagram.com/manamuzelectric"
				target="_blank"
				rel="noopener noreferrer"
			>
				<i className="fab fa-instagram" />
			</a>
		</div>
		<p>
			<b>Head quarters:</b> <br /> No 68B Chime Avenue, Assemblies of God
			Bus stop, New Haven, Enugu, Enugu state, Nigeria
		</p>
		<p>
			<b>Phone:</b>
			<br />{" "}
			<a className={styles.contact} href="tel:+234-916-793-0610">
				+234 (0)916 793 0610
			</a>{"; "}
			<a className={styles.contact} href="tel:+234-807-388-3547">
				+234 (0)807 388 3547
			</a>{" "}
		</p>
		<p>
			<b>Email:</b>
			<br />{" "}
			<a
				className={styles.contact}
				rel="noopener noreferrer"
				target="_blank"
				href="mailto:connect@manamuz.com"
			>
				connect@manamuz.com
			</a>
		</p>
		<div className={styles.copyright}>
			Copyright &copy; 2019 Manamuz Electric Ltd. All rights reserved.
		</div>
	</footer>
);

export default footer;
