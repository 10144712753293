import React from "react";

import styles from "../CSS/each_service_detail.module.css";

const PostHarvestGrid = () => (
	<div id="post-harvest-grid" className={styles.service_detail}>
		<h2>Post-harvest Grid</h2>
		<p>
			African Agriculture is a multibillion-dollar industry. It is currently just fragmented and affected by
			post-harvest losses. Through the postharvest grid platform, we eliminate this fragmentation problem and
			develop a robust infrastructure upon which food businesses and factories can access local farmers. Restaurants,
			hotels, food processors, and exporters are the biggest beneficiaries of this platform as they can consistently
			access high-value produce at the expected quality.
		</p>

		<p>
			Post-Harvest Grid Aggregates Agricultural demand and supply data, transform it into actionable information,
			then uses this information to boost agricultural productivity. It is a Trade platform powered by our solar
			Energy and Agtech solutions.
		</p>

		<p>
			It influences the adoption of available modern technologies for the Agro Value Chain by farmers who were
			previously not motivated to explore them. Through this platform, food businesses can order fresh produce from
			any farmer who is connected to any of our storage facilities deployed across the country.
		</p>

		<p>
			For instance, through the post-harvest grid, a restaurant in Enugu State, Nigeria can easily order products
			from a farmer in Kano. Also, exporters can easily reach out to thousands of farmers from a central portal.
		</p>

	</div>
);

export default PostHarvestGrid;
