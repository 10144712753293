import React from "react";

//styles
import styles from "../CSS/about_the_company.module.css";

const aboutCompany = () => (
	<section className={styles.section}>
		<h1>About Us</h1>
		<p>
			Conceptualised in 2008 by two students of the Faculty of Engineering at the University of Nigeria, Manamuz Electric started as a clean tech company that deploys cutting-edge electrical and solar energy systems in African homes, businesses, and communities.
		</p>
		<p>
			14 years later and over 300 solar energy projects deployed, we are currently focused on the Energy-Agriculture Nexus as we are committed to advancing productive use of solar energy for Agricultural applications.
		</p>
		<p>
			Hence, we develop clean energy and Agri tech solutions to decarbonize the Agriculture value chain, enhance biodiversity and sustainably solve global food security problems, especially food waste.
		</p>
		<p>
			In line with this, we have developed a vertical integration approach for using clean energy to optimize different Agricultural product supply chains. Hence we deploy solar powered storage and processing solutions in aggregation centers then provide forward vertical or backward vertical integration depending on the needs of the supply chain.
		</p>
		<p>
			We have started with the perishable food supply chain through a product called the Coldbox Store (www.coldboxstore.com) which is a network of solar powered, refrigerated warehouse solutions that are managed through a central portal and designed to boost the resilience of perishable food supply chains such as meat, fish , fruits and vegetables.
		</p>
	</section>
);

export default aboutCompany;
