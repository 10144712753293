import React, { lazy, Suspense } from "react";
import Image from "../../../assets/munaroof.jpg";
//styles
import styles from "../CSS/banner.module.css";

const BackgroundVideo = lazy(() => import("./homepage_video"));
const fallbackImage = (
	<img
		src={Image}
		className={styles.fallbackImage}
		alt="engineer on the roof fixing solar panels"
	/>
);

const banner = () => (
	<div className={styles.banner}>
		<Suspense fallback={fallbackImage}>
			<BackgroundVideo />
		</Suspense>
		{fallbackImage}
		<div className={styles.overlay}></div>
		<h1 className={styles.banner_title}>
			Engineering a Sustainable and  <br /> Clean Energy Future.
		</h1>
		<p className={styles.summary}>
			Manamuz Electric advances productive use of clean energy in the agro value chain.
		</p>
		<button type="button" className={styles.cta}>
			<a href="./#contact">Contact Us</a>
		</button>
	</div>
);

export default banner;
