import React from "react";
import PropTypes from "prop-types";

import classes from "../CSS/navigation_items.module.css";

const navigationItems = props => (
	<ul
		data-testid="nav-items"
		className={
			props.horizontal ? classes.Ul_horizontal : classes.Ul_vertical
		}
	>
		{props.children}
	</ul>
);

navigationItems.propTypes = {
	horizontal: PropTypes.bool.isRequired
}

export default navigationItems;
