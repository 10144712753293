import React from "react";
import PropTypes from "prop-types";

//style
import styles from "../CSS/backdrop.module.css";

const topBackdrop = ({ onClick }) => (
	<div
		className={styles.backdrop}
		data-testid="backdrop"
		onClick={() => onClick()}
	/>
);

topBackdrop.propTypes = {
	onClick: PropTypes.func.isRequired
};

export default topBackdrop;
