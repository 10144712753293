import React, { Component } from "react";
import TopNav from "../../../components/Layout/JS/topNav";
import RightDrawer from "../../../components/UI/JS/right_side_drawer";
import NavigationItem from "../../../components/UI/JS/navigation_item";
import NavigationItems from "../../../components/UI/JS/navigation_items";
import Backdrop from "../../../components/UI/JS/backdrop";

class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rightDrawerOpen: false
		};
		this.closeDrawer = this.closeDrawer.bind(this);
		this.openDrawer = this.openDrawer.bind(this);
	}

	closeDrawer() {
		this.setState({ rightDrawerOpen: false });
	}

	openDrawer() {
		this.setState({ rightDrawerOpen: true });
	}

	render() {
		const { rightDrawerOpen } = this.state;
		return (
			<>
				<TopNav openDrawer={this.openDrawer} />
				{this.props.children}
				<RightDrawer open={rightDrawerOpen} onClick={this.closeDrawer}>
					<NavigationItems horizontal={false}>
						<NavigationItem itemName="HOME" to="/" />
						<NavigationItem itemName="ABOUT US" to="/About_Us" />
						<NavigationItem itemName="FAQ" to="/FAQ" />
					</NavigationItems>
				</RightDrawer>
				{rightDrawerOpen ? (
					<Backdrop onClick={this.closeDrawer} />
				) : null}
			</>
		);
	}
}

export default Layout;
