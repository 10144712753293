import React from "react";
import styles from "../CSS/meet_the_team.module.css";
import Uzor from "../../../assets/uzor.jpg";
import Seyi from "../../../assets/oluwaseyi.jpg";
import pekoye from "../../../assets/Pekoye.jpg";
import miracle from "../../../assets/miracle.jpg";
import David from "../../../assets/david.jpg";
import Patrick from "../../../assets/Patrick.jpg";
import Chima from "../../../assets/Chima.jpg";
import Charles from "../../../assets/munachi.jpg";

const meetTheTeam = () => (
	<section className={styles.section}>
		<h1>Meet the team</h1>
		<div className={styles.members}>
			<div className={styles.each}>
				<img src={Uzor} alt="CEO Uzochukwu Mbamalu" />
				<span className={styles.name}>Uzochukwu Mbamalu</span>
				<p>CEO</p>
				<span className={styles.qualification}>
					B.Eng. Electronics Engineering, UNN
				</span>
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/uzmanamuz/"
				>
					LinkedIn Profile
				</a>
			</div>
			<div className={styles.each}>
				<img alt="CTO Charles Aliozo" src={Charles} />
				<span className={styles.name}>Charles Aliozo</span>
				<p>Technical Director</p>
				<span className={styles.qualification}>
					B.Eng. Electrical Engineering, UNN
				</span>
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/charles-aliozo-4a6085123/"
				>
					LinkedIn Profile
				</a>
			</div>
			<div className={styles.each}>
				<img src={miracle} alt="Company Secretary, Miracle Ebere" />
				<span className={styles.name}>
					Miracle Ebere
				</span>
				<p>COO</p>
				<span className={styles.qualification}>
					HND, Chemical Engineering, IMT, Enugu
				</span>
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/miracle-ebere-48993617a/"
				>
					LinkedIn Profile
				</a>
			</div>
			<div className={styles.each}>
				<img src={Seyi} alt="Head of Partnerships Oluwaseyi Sunday" />
				<span className={styles.name}>
					Oluwaseyi Sunday
				</span>
				<p className={styles.marginTop10}>Head of Partnerships</p>
				<span className={styles.qualification}>
					B.A., History and International Relations, FUL
				</span>
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/oluwaseyi-sunday-6aab86227/overlay/photo/"
				>
					LinkedIn Profile
				</a>
			</div>
			<div className={styles.each}>
				<img src={David} alt="Project Manager David Okenwa" />
				<span className={styles.name}>
					David Okenwa
				</span>
				<p>Project Manager</p>
				<span className={styles.qualification}>
					BEng. Mechanical Engineering, UNN
				</span>
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/david-okenwa/"
				>
					LinkedIn Profile
				</a>
			</div>
			<div className={styles.each}>
				<img
					src={pekoye}
					alt="Co-ordinator, Gigawatt project, Peculiar Okoye"
				/>
				<span className={styles.name}>
					Peculiar Okoye
				</span>
				<p>Head, Corporate Social Responsibility</p>
				<span className={styles.qualification}>LLB, ESUT</span>
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/treasure-peculiar-okoye-540560190/"
				>
					LinkedIn Profile
				</a>
			</div>
			<div className={styles.each}>
				<img src={Patrick} alt="Company Secretary, Miracle Ebere" />
				<span className={styles.name}>
					Patrick Ocheja
				</span>
				<p>IT Consultant</p>
				<span className={styles.qualification}>
					PhD, Informatics, Kyoto University, Japan
				</span>
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/patrickocheja"
				>
					LinkedIn Profile
				</a>
			</div>
			{ false && <div className={styles.each}>
				<img src={Chima} alt="Company Secretary, Miracle Ebere"/>
				<span className={styles.name}>
				Chima Nmerenu, (R&D consultant)
				</span>
				<span className={styles.qualification}>
				Msc, Electronics and telecoms, Higher Institute of
				Electronics, Paris
				</span>
				<a
				rel="noopener noreferrer"
				href="https://www.linkedin.com/in/chima-nmerenu"
				>
				LinkedIn Profile
				</a>
				</div>}
		</div>
	</section>
);

export default meetTheTeam;
